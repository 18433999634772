.input-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center !important;
  min-width: 95% !important;
  max-width: 95% !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
}

/* .input-text-container#input-dust-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 95%;
  max-width: 95%;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  align-self: center;
} */

.input-slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 95%;
  max-width: 95%;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
}

.text-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  font-family: var(--default-font);
  margin: 0;
  padding: 5px 0;
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1);
  position: relative;
}

.text-info-container#acreage-info-container {
  position: relative;
}

.text-form-label,
.file-form-label {
  font-size: 20px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#calculator-icon-btn {
  display: flex;
  position: absolute;
  left: -50px;
}

#calculator-icon-btn:hover {
  cursor: pointer;
  background-color: rgba(2, 99, 165, 0.249);
}

.calculator-icon {
  color: var(--label-color);
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.5));
}

/* ! FORM CONTROL */
#text-form {
  box-shadow: var(--input-box-shadow);
  background-color: var(--input-white-bg);
  border-radius: 5px;
  border: none;
  outline: none;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#text-form.required {
  outline: 2px solid var(--input-outline-required) !important;
  box-shadow: var(--input-box-shadow);
}

#text-form.revise-input {
  outline: 2px solid var(--input-outline-required) !important;
  border: none;
}

#text-form.revised-input {
  outline: 2px solid var(--input-outline-revised) !important;
}

#text-form.spccAlert {
  outline: 2px solid var(--input-outline-revised) !important;
}

/* - SAME AS MuiInputBase-root & MuiOutlinedInput-root */
.text-field-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.text-notchedOutline {
  border: none !important;
  outline: none !important;
}

.text-notchedOutline:focus-within {
  outline: none !important;
}

/** TEXT FIELD */
.text-field-input#text-field {
  color: var(--input-text-color) !important;
  font-size: 18px;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight) !important;
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  font-family: var(--default-font) !important;
  height: inherit;
  background-color: transparent;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#text-field.disable-text {
  border: 2px solid rgba(102, 102, 102, 0.724);
}

#text-field:disabled:hover {
  cursor: not-allowed;
}

/* $ PLACEHOLDER $ */
.predictive-placeholder#predictive-placeholder {
  position: absolute;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  color: var(--input-place-holder-color);
  text-indent: 8px;
  text-shadow: none;
  box-shadow: none;
  font-size: 18px;
  letter-spacing: 0.7px;
  padding: 0 !important;
  margin: 0 !important;
  border: none;
  border-radius: 5px;
  outline: none;
  pointer-events: none;
  user-select: none;
  background-color: transparent;
}

/* | INPUT LABELS | */
#text-field-label {
  font-family: var(--default-font) !important;
  color: var(--input-label-color) !important;
  font-weight: var(--default-font-weight) !important;
  transform: translate(14px, 12px) scale(1);
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}
#text-field-label.text-field-label-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(0, -18px) scale(0.75) !important;
}

.revise-input #text-field-label {
  color: var(--input-label-color) !important;
  transform: translate(14px, 9px) scale(1);
}

.revise-input #text-field-label.text-field-label-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(0, -18px) scale(0.75) !important;
  letter-spacing: 0.7px;
}

.revised-input #text-field-label {
  color: var(--shrink-label-color) !important;
  transform: translate(14px, 9px) scale(1);
}

.revised-input #text-field-label.text-field-label-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(0, -18px) scale(0.75) !important;
}

/* + OTHER TEXT (Project Type) */
#otherText {
  width: 480px;
  margin-right: 25px;
  margin-left: 14px;
}

/* | CLEAR BUTTON | */
.text-field-clear-adornment#text-adornment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 0 !important;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

#clear-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 10px;
  pointer-events: auto;
}

.text-field-clear-adornment#text-adornment:hover {
  cursor: pointer;
  background-color: rgba(255, 88, 88, 0.276);
}

.close-icon {
  font-size: 25px;
  fill: var(--close-icon-color);
  color: var(--close-icon-color);
  stroke: var(--close-icon-color);
  margin: 0;
  padding: 0;
}

/*| SLIDER | */
.slider-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--default-font);
  margin: 0;
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}

.slider-helper-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
}

.sliderRoot#sNum-slider {
  margin: 0;
  padding: 15px 0;
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}

.sliderRoot#sNum-slider.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.sliderRoot#sNum-slider .sliderThumb {
  background-color: rgb(215, 215, 215) !important;
}

.invalid-slider.sliderRoot#sNum-slider .sliderThumb {
  background-color: rgb(234, 39, 39) !important;
}

.sliderRoot#sNum-slider .sliderMark {
  color: rgb(255, 255, 255) !important;
  height: 10px !important;
  margin: 0;
  padding: 0;
}

.invalid-slider.sliderRoot#sNum-slider .sliderMark {
  color: rgb(234, 39, 39) !important;
}

.sliderRoot#sNum-slider .sliderMarkLabel {
  color: rgba(224, 224, 224, 0.705) !important;
  font-weight: var(--default-font-weight) !important;
}

.sliderRoot#sNum-slider .sliderMarkLabel.sliderMarkLabelActive {
  color: rgb(255, 255, 255) !important;
  font-weight: var(--default-font-weight) !important;
}

.sliderRoot#sNum-slider .sliderRail {
  background-color: rgb(242, 242, 242) !important;
  opacity: 0.5 !important;
  box-shadow: none !important;
  border-color: none !important;
}

.invalid-slider.sliderRoot#sNum-slider .sliderRail {
  background-color: rgba(255, 100, 100, 0.632) !important;
  opacity: 1 !important;
  border: 1px solid rgba(255, 100, 100, 0.632) !important;
  box-shadow: none !important;
  border-radius: 0px;
  padding-right: 0 !important;
}

.slider-helper-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  max-height: fit-content;
}

.invalid-slider-helper-text {
  text-align: center;
  font-size: 15px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  color: rgb(246, 218, 34) !important;
  outline: 1px solid rgba(255, 0, 0, 0.802) !important;
  outline: none !important;
  text-shadow: 0px 0.5px 0.5px rgba(247, 255, 25, 0.406) !important;
  width: 100%;
  padding: 3px 0;
  margin: 0;
}

.revised-slider-helper-text {
  color: rgb(246, 188, 51) !important;
  outline: 2px solid rgb(246, 188, 51);
  background-color: #2e3335ec;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  text-align: center;
  text-indent: 0px;
  font-size: 13.5px;
  width: 100%;
  height: auto;
  max-height: 50px;
  letter-spacing: 0.25px;
  font-weight: 550 !important;
  line-height: 1.2;
  text-rendering: optimizeLegibility !important;
  z-index: 998 !important;
}

.text-info-circ:hover {
  cursor: pointer;
}

/* > HELPER TEXT  */
.helper-text {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  text-align: center;
  margin: 0 !important;
  position: absolute;
  top: 50px;
  height: auto;
}

#two-helper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.helper-invalid-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
}

.helper-revised-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
}

.helper-text.invalid-input-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(216, 18, 18) !important;
  outline: 2px solid rgb(219, 29, 29) !important;
  margin: 0px 0px 0px 0px !important;
  padding: 4px 0px !important;
  text-align: center;
  text-indent: 0px;
  font-size: 15px;
  height: auto;
  letter-spacing: 0.25px;
  background-color: rgba(239, 235, 235, 0.953);
  line-height: 1;
  text-rendering: optimizeLegibility !important;
  z-index: 998 !important;
}

.helper-text.revised-input-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--p-yellow) !important;
  background-color: transparent;
  margin: 0 !important;
  padding: 4px 0 !important;
  text-align: center !important;
  text-indent: 0px;
  font-size: 15px;
  height: auto;
  max-height: 50px;
  letter-spacing: 0.25px;
  line-height: 1;
  text-rendering: optimizeLegibility !important;
  z-index: 998 !important;
}

.revision-marker {
  display: flex;
  position: relative;
  right: 10px;
  top: 5px;
  user-select: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  color: rgb(197, 18, 18) !important;
  text-shadow: 0px 0.5px 0.5px rgba(217, 98, 98, 0.413) !important;
  margin: 0;
  padding: 0;
  font-size: 24px;
  width: fit-content;
  height: fit-content;
}

.revised-marker {
  display: flex;
  position: relative;
  right: 10px;
  top: 5px;
  user-select: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  color: rgb(246, 188, 51);
  margin: 0;
  padding: 0;
  font-size: 24px;
  width: fit-content;
  height: min-content;
  text-shadow: 0px 0.5px 0.5px rgba(149, 104, 5, 0.495) !important;
}

.required-text {
  color: rgb(246, 218, 34) !important;
  font-family: var(--default-font);
  margin: 0;
  padding: 0;
  font-size: 13.5px;
  width: fit-content;
  font-weight: 650;
  text-shadow: 0px 0px 1.5px rgba(66, 72, 1, 0.623) !important;
}

.spcc-alert-text {
  color: var(--shrink-label-color) !important;
  font-family: var(--default-font);
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 15px;
  width: fit-content;
  font-weight: var(--default-font-weight) !important;
  text-shadow: 0px 0px 1.5px rgba(66, 72, 1, 0.623) !important;
}

.fill-text-info {
  color: white !important;
  width: fit-content;
  margin: 0;
  padding: 0;
  font-size: 13.5px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
}

#apple-tab {
  font-weight: 550 !important;
  color: rgb(220, 220, 220);
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: rgb(40, 40, 43);
  padding: 0 2px 1px 4px;
  box-shadow: 0.35px 0.5px 1px 0.5px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

/*  todo SMALL */
@media screen and (width < 1450px) {
  .text-form-label,
  .file-form-label {
    font-size: 18px !important;
  }

  .input-text-container,
  .input-slider-container {
    width: 890px;
    align-self: center;
    margin-bottom: 10px;
    padding: 5px 15px;
  }

  /* .input-text-container#input-dust-container {
    width: 890px;
    margin-bottom: 5px !important;
    padding: 5px 10px;
  } */

  .text-info-container {
    width: 465px;
  }

  /* .text-info-container#dust-info-container {
    width: 435px;
    padding: 5px 0;
    height: 40px;
  } */

  #text-form {
    width: 400px;
    height: 40px;
  }

  .dustPermitNumber-form-control#text-form {
    width: 365px;
    height: 32px;
  }

  .text-field-root {
    width: 100% !important;
  }
  .text-field-input#text-field {
    width: 370px;
    font-size: 17px;
  }

  /* -PLACEHOLDER */
  .predictive-placeholder#predictive-placeholder {
    width: 370px;
    font-size: 17px;
    height: 40px;
  }

  /* -LABELS */
  #text-field-label {
    transform: translate(11px, 9px) scale(0.95) !important;
  }
  .revise-input #text-field-label {
    transform: translate(11px, 9px) scale(0.95);
  }
  .revised-input #text-field-label {
    transform: translate(11px, 9px) scale(0.95);
  }
  #text-field-label.text-field-label-shrink {
    transform: translate(3px, -18px) scale(0.75) !important;
  }
  .revise-input #text-field-label.text-field-label-shrink {
    transform: translate(3px, -18px) scale(0.75) !important;
  }
  .revised-input #text-field-label.text-field-label-shrink {
    transform: translate(3px, -18px) scale(0.75) !important;
  }
  .dustPermitNumber-text-field-label#text-field-label {
    transform: translate(11px, 8px) scale(0.8) !important;
  }
  .dustPermitNumber-text-field-label#text-field-label.text-field-label-shrink {
    transform: translate(3px, -19px) scale(0.7) !important;
  }

  /* -SLIDER */
  #sNum-label {
    width: 310px;
    text-align: left;
  }
  .slider-info-container {
    width: 465px;
  }
  .sliderRoot#sNum-slider {
    width: 400px;
  }
  .sliderMark {
    height: 10px;
  }
  .slideMarkLabel {
    font-size: 14px;
  }

  .text-info-circ {
    height: 28px;
    width: 28px;
  }

  .text-info-circ#info-dustPermitNumber {
    height: 24px;
    width: 24px;
  }

  /* -HELPER TEXT */
  #helper-text-container {
    width: 390px;
    position: absolute;
    top: 72px;
    right: 85px;
  }
}

/** MEDIUM */
@media screen and (1450px <= width < 1750px) {
  .text-form-label {
    font-size: 20px !important;
  }

  .file-form-label,
  #dustPermitNumber-label {
    font-size: 18px !important;
  }

  .input-text-container,
  .input-slider-container {
    /* width: 945px; */
    width: 95% !important;
    align-self: center;
    margin-bottom: 15px;
    padding: 10px 15px;
  }

  /* .input-text-container#input-dust-container {
    width: 945px;
    padding: 5px 10px;
    margin-bottom: 2px !important;
  } */

  .text-info-container {
    width: 480px;
    height: 45px;
  }

  .text-info-container#dust-info-container {
    width: 435px;
    padding: 5px 0;
  }

  #text-form {
    width: 425px;
    height: 40px;
  }

  .dustPermitNumber-form-control#text-form {
    width: 360px;
    height: 35px;
  }

  .text-field-root {
    width: 100% !important;
    height: 100% !important;
  }

  .text-field-input#text-field {
    height: inherit;
    width: 390px;
    font-size: 18px;
  }

  .text-info-circ {
    height: 30px;
    width: 30px;
  }

  .text-info-circ#info-dustPermitNumber {
    height: 24px;
    width: 24px;
  }

  /* -PLACEHOLDER */
  .predictive-placeholder#predictive-placeholder {
    width: 380px;
    font-size: 18px;
  }
  /* -LABELS */
  #text-field-label {
    transform: translate(11px, 9px) scale(1) !important;
  }
  .revise-input #text-field-label {
    transform: translate(11px, 9px) scale(1);
  }
  .revised-input #text-field-label {
    transform: translate(11px, 9px) scale(1);
  }
  #text-field-label.text-field-label-shrink {
    transform: translate(3px, -18px) scale(0.82) !important;
  }
  .revise-input #text-field-label.text-field-label-shrink {
    transform: translate(3px, -18px) scale(0.82) !important;
  }
  .revised-input #text-field-label.text-field-label-shrink {
    transform: translate(3px, -18px) scale(0.82) !important;
  }

  .dustPermitNumber-text-field-label#text-field-label {
    transform: translate(11px, 8px) scale(0.82) !important;
  }

  .dustPermitNumber-text-field-label#text-field-label.text-field-label-shrink {
    transform: translate(3px, -19px) scale(0.7) !important;
  }

  /* -SLIDER */
  #sNum-label {
    width: 310px;
    text-align: left;
  }
  .slider-info-container {
    max-width: 480px;
    width: 480px;
  }
  .sliderRoot#sNum-slider {
    width: 385px;
  }
  .sliderMark {
    height: 10px;
  }
  .sliderThumb {
    height: 15px !important;
    width: 15px !important;
  }

  .sliderRail {
    height: 3px !important;
  }
  .sliderMarkLabel {
    font-size: 14px !important;
  }

  /* -HELPER TEXT */
  #helper-text-container {
    width: 415px;
    position: absolute;
    top: 72px;
    right: 80px;
  }
}

/** MEDIUM */
@media screen and (1750px <= width < 2150px) {
  .input-text-container {
    /* width: 1090px; */
    margin: 5px 0 5px 0;
    padding: 5px 15px;
  }

  .input-slider-container {
    width: 1090px;
    align-self: center;
    margin-bottom: 10px;
    padding: 5px 15px;
  }

  .text-info-container {
    width: 535px;
    height: 60px;
    padding: 5px 0;
  }

  #dust-info-container {
    width: 455px;
    padding: 5px 0;
  }

  .slider-info-container {
    width: 535px;
    height: 120px;
    padding: 0;
  }

  .slider-helper-text-container {
    gap: 20px;
    width: 490px;
  }

  .text-form-label {
    font-size: 20px !important;
  }

  .file-form-label {
    font-size: 20px !important;
  }

  #text-form {
    width: 470px;
    height: 44px;
    margin: 0 !important;
    padding: 0 !important;
  }

  .sliderRoot#sNum-slider {
    width: 470px;
  }
  .slider-helper-text {
    width: 470px;
  }

  .dustPermitNumber-form-control#text-form {
    width: 385px;
    height: 40px;
  }

  .text-field-root {
    width: 100% !important;
    height: 100% !important;
  }

  .text-field-input#text-field {
    height: inherit;
    font-size: 18px;
  }

  .text-info-circ {
    height: 30px;
    width: 30px;
  }

  .text-info-circ#info-dustPermitNumber {
    height: 25px;
    width: 25px;
  }

  /* -PLACEHOLDER */
  .predictive-placeholder#predictive-placeholder {
    width: 460px;
    height: 44px;
    font-size: 18px;
  }

  /* -LABELS */
  #text-field-label,
  .revise-input #text-field-label,
  .revised-input #text-field-label {
    transform: translate(11px, 10px) scale(1.1);
  }

  #text-field-label.text-field-label-shrink,
  .revise-input #text-field-label.text-field-label-shrink,
  .revised-input #text-field-label.text-field-label-shrink {
    transform: translate(3px, -19px) scale(0.85) !important;
  }

  .dustPermitNumber-text-field-label#text-field-label {
    transform: translate(11px, 9px) scale(1) !important;
  }

  .dustPermitNumber-text-field-label#text-field-label.text-field-label-shrink {
    transform: translate(3px, -19px) scale(0.8) !important;
  }

  .sliderMark {
    height: 10px;
  }
  .sliderMarkLabel {
    font-size: 14px;
  }

  /* - HELPER TEXT */
  #helper-text-container {
    width: 460px;
    position: absolute;
    top: 76px;
    right: 100px;
  }
}

@media screen and (2150px <= width) {
  .input-text-container {
    width: 1150px;
    align-self: center;
    margin-bottom: 10px;
    padding: 5px 15px;
  }

  .input-slider-container {
    width: 1150px;
    align-self: center;
    margin-bottom: 15px;
    padding: 10px 15px;
  }

  .text-info-container {
    width: 550px;
    height: 70px;
    padding: 5px 0;
  }

  .slider-info-container {
    width: 555px;
    height: 120px;
    padding: 0;
  }

  .slider-helper-text-container {
    gap: 20px;
    width: 490px;
  }

  .text-form-label {
    font-size: 24px !important;
  }

  .file-form-label {
    font-size: 19px !important;
  }

  #text-form {
    width: 490px;
    height: 45px;
    margin: 0 !important;
    padding: 0 !important;
  }

  .sliderRoot#sNum-slider {
    width: 470px;
    left: 10px;
    position: relative;
  }
  .slider-helper-text {
    width: 490px;
  }

  .text-field-root {
    width: 100% !important;
    height: 100% !important;
  }

  .text-field-input#text-field {
    height: inherit;
    font-size: 18px;
  }

  .text-info-circ {
    height: 28px;
    width: 28px;
  }

  /* -PLACEHOLDER */
  .predictive-placeholder#predictive-placeholder {
    width: 490px;
    height: 45px;
    font-size: 18px;
  }

  /* -LABELS */
  #text-field-label,
  .revise-input #text-field-label,
  .revised-input #text-field-label {
    transform: translate(11px, 10px) scale(1.2);
  }

  #text-field-label.text-field-label-shrink,
  .revise-input #text-field-label.text-field-label-shrink,
  .revised-input #text-field-label.text-field-label-shrink {
    transform: translate(3px, -21px) scale(0.85) !important;
  }

  .dustPermitNumber-text-field-label#text-field-label {
    transform: translate(10px, 8px) scale(0.88) !important;
  }

  .dustPermitNumber-text-field-label#text-field-label.text-field-label-shrink {
    transform: translate(3px, -19px) scale(0.78) !important;
  }

  .sliderMark {
    height: 10px;
  }
  .sliderMarkLabel {
    font-size: 14px;
  }

  /* - HELPER TEXT */
  #helper-text-container {
    width: 460px;
    position: absolute;
    top: 76px;
    right: 100px;
  }
}
