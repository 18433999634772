/*| Dashboard Navbar */
#app-bar {
  display: flex;
  top: 105px;
  justify-content: center;
  background-color: transparent;
  background-color: var(--app-bar-bg);
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.262);
  height: 55px;
  z-index: 9997;
  min-width: 100vw;
}

#app-bar-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  max-width: 99vw;
  min-width: 99vw;
  min-height: 55px;
  max-height: 55px;
  padding: 0;
  margin: 0 0 0 0 !important;
}

#left-portal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  width: fit-content;
  margin-right: auto;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#portal-heading {
  color: var(--main-header-color);
  font-size: clamp(20px, 1.2vw, 24px);
  letter-spacing: 0.65px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-shadow: none;
  margin: 0;
  padding: 0;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font);
  transition: all 0.15s cubic-bezier(0.25, 1, 0.5, 1) !important;
}
#sidebar-hamburger {
  pointer-events: all;
  height: 40px;
  width: 40px;
  margin: 0;
  border-radius: 50%;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#sidebar-hamburger:hover {
  cursor: pointer;
  background-color: rgba(164, 164, 164, 0.565);
}

#sidebar-hamburger:disabled:hover {
  cursor: not-allowed;
}

#hamburger-closed {
  height: 30px;
  width: 30px;
  color: var(--main-header-color);
}

#hamburger-opened {
  height: 35px;
  width: 35px;
  color: var(--main-header-color);
}

#sidebar-hamburger #hamburger-closed {
  z-index: 9998;
  pointer-events: all;
}

#sidebar-hamburger #hamburger-closed {
  z-index: 9998;
  pointer-events: all;
}

.sidebar-hamburger:hover {
  background-color: rgba(69, 69, 69, 0.87);
}

#create-new-request-btn {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 3px;
  width: 150px;
  height: 33px;
  border: none;
  border-radius: 8px;
  background-color: rgb(16, 193, 16);
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#create-new-request-btn:hover {
  cursor: pointer;
  background-color: var(--btn-green-hover);
}

.appbar-btn-text#add-request-span {
  font-size: 14px;
  line-height: 1;
  text-shadow: none !important;
  color: var(--btn-white-text);
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: fit-content;
  text-shadow: 0px 1px 1px rgb(160, 160, 160);
  letter-spacing: 0px;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#add-request {
  stroke: var(--btn-white-text);
  stroke-width: 1px;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 18px;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#return-home-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  pointer-events: all;
  border: none;
  background-color: var(--btn-light-blue);
  height: 33px;
  width: 150px;
  border-radius: 8px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#return-home-btn:hover {
  cursor: pointer;
  background-color: var(--btn-light-blue-hover);
}

#return-home-btn.Mui-disabled {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed !important;
}

#create-new-request-btn:disabled {
  background-color: rgba(185, 185, 185, 0.549);
  color: rgba(231, 231, 231, 0.601);
  cursor: not-allowed !important;
}

.appbar-btn-text#return-home-span {
  font-size: 14px;
  line-height: 1;
  text-shadow: none !important;
  color: var(--btn-white-text);
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: fit-content;
  text-shadow: 0px 1px 1px rgb(160, 160, 160);
  letter-spacing: 0px;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#return-home-btn #return-home-icon {
  fill: var(--btn-white-text);
  padding: 0;
  font-size: 18px;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

@media screen and (width < 1025px) {
  #left-portal {
    gap: 20px;
  }

  #portal-heading {
    font-size: 18px;
  }
  #sidebar-hamburger {
    height: 30px;
    width: 30px;
  }

  #hamburger-closed {
    height: 25px;
    width: 25px;
    color: var(--main-header-color);
  }

  #hamburger-opened {
    height: 30px;
    width: 30px;
    color: var(--main-header-color);
  }

  #sidebar-hamburger #hamburger-closed {
    z-index: 9998;
    pointer-events: all;
  }

  #create-new-request-btn {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 5px;
    width: 122px;
    height: 26px;
    border: none;
    border-radius: 8px;
    background-color: rgb(16, 193, 16);
    transition: all 0.3s ease-in-out;
  }

  #create-new-request-btn:hover {
    cursor: pointer;
    background-color: var(--btn-green-hover);
  }

  .appbar-btn-text#add-request-span {
    font-size: 11px;
  }

  #add-request {
    font-size: 14px;
  }

  #return-home-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    pointer-events: all;
    border: none;
    background-color: var(--btn-light-blue);
    height: 30px;
    width: 135px;
    border-radius: 8px;
    font-weight: var(--default-font-weight);
    font-family: var(--default-font);
  }

  .appbar-btn-text#return-home-span {
    font-size: 11px;
  }

  #return-home-btn #return-home-icon {
    font-size: 14px;
  }
}
