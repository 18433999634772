/* | Component Container */
.input-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 95% !important;
  max-width: 95% !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
}

/* | Label, DateField, and InfoCircle Container */
.date-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  font-family: var(--default-font);
  margin: 0;
  padding: 5px 0;
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}

/* - DateField/TextField Form Control */
#date-form {
  box-shadow: var(--input-box-shadow);
  background-color: var(--input-white-bg);
  border-radius: 5px;
  border: none;
  outline: none;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#date-form.required {
  outline: 2px solid var(--input-outline-required) !important;
  box-shadow: var(--input-box-shadow) !important;
}

/* - Outlined Input */
.date-field-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.date-notchedOutline,
.date-notchedOutline:focus-within {
  border: none !important;
  outline: none !important;
}

.date-field-root #date-field {
  color: var(--input-text-color) !important;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight);
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  font-family: var(--default-font);
  height: inherit;
  background-color: transparent;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#date-field-label {
  font-family: var(--default-font);
  color: var(--input-label-color) !important;
  font-weight: var(--default-font-weight) !important;
  transform: translate(14px, 9px) scale(1);
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#date-field-label.MuiInputLabel-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(9px, -18px) scale(0.75);
}

#date-field-adornment {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 0 !important;
  padding: 5px !important;
  border-radius: 50%;
  background-color: transparent;
}

#date-field-adornment:hover {
  cursor: pointer;
  background-color: rgba(101, 188, 220, 0.38);
}

#date-input-adornment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 0 !important;
  padding: 5px !important;
  transition: background 0.35s ease-in-out;
}

#date-input-adornment-btn:hover {
  cursor: pointer;
  background-color: rgba(35, 180, 202, 0.38);
}

#date-input-adornment {
  color: var(--main-blue) !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 25px;
}

.date-info-circ:hover {
  cursor: pointer;
}

/* % Date Calendar Popover */
#date-popper #date-desktop-paper {
  background-color: rgba(240, 240, 240, 0.992);
}

.MuiDateCalendar-root {
  background-color: transparent;
  outline: 2px solid var(--app-bar-bg) !important;
}

/* ~ Month/Year Header/Label */
#date-calendar-header {
  background-color: var(--app-bar-bg) !important;
}

#date-calendar-header .MuiPickersCalendarHeader-label {
  color: var(--argus-blue-light) !important;
  font-family: var(--default-font);
  font-weight: 550;
}

/* ~ Switch Month/Year Arrow */
#date-switch-view-icon {
  color: var(--argus-blue-light) !important;
}

/* ~ Left/Right Arrows */

#date-next-icon-btn.Mui-disabled #date-right-arrow,
#date-previous-icon-btn.Mui-disabled #date-left-arrow {
  opacity: 0.55;
}

#date-left-arrow,
#date-right-arrow {
  color: var(--argus-blue-light) !important;
}

.MuiDayCalendar-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.MuiDayCalendar-slideTransition {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.MuiDayCalendar-slideTransition,
.MuiDayCalendar-slideTransition > * {
  position: static !important;
}

/* ~ Day Picker Container */
.MuiDayCalendar-monthContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 225px !important;
  margin: 0 !important;
}

/* ~ Week Container */
.MuiDayCalendar-weekContainer {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  height: 35px !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* ~ Calendar Day Buttons */
#calendar-day {
  font-weight: 550 !important;
  color: rgba(68, 76, 80, 0.962) !important;
}

.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
  height: 30px !important;
  width: 30px !important;
}

#calendar-day.Mui-selected {
  color: var(--btn-white-text) !important;
  background-color: rgb(60, 182, 207) !important;
  font-weight: var(--default-font-weight);
}

#calendar-day:not(.Mui-disabled):hover {
  background-color: rgba(0, 141, 169, 0.893) !important;
  color: var(--btn-white-text) !important;
  transition: all 0.3s ease;
}

#calendar-day.Mui-disabled {
  opacity: 0.5;
}

/* ~ Weekend Days */
#calendar-day:nth-child(1),
#calendar-day:nth-child(7) {
  color: rgb(214, 40, 40) !important;
}

/* ~ Today on the Calendar */
#calendar-day.MuiPickersDay-today:not(.Mui-selected) {
  color: rgb(36, 36, 36);
  border: 1px solid var(--app-bar-bg) !important;
  border-radius: 1px;
  padding: 2px 2px 2px 2px !important;
  transition: all 0.3s ease;
}

#calendar-day.MuiPickersDay-today:not(.Mui-selected):hover {
  background-color: rgba(53, 162, 184, 0.771) !important;
  color: var(--btn-white-text) !important;
  border: none;
  border-radius: 50%;
}

/* ~ Day of Week Header */
.MuiDayCalendar-header {
  display: flex;
  align-self: center !important;
  justify-content: center !important;
  align-items: center;
  min-width: 100% !important;
  margin: 0 !important;
  box-shadow: 0px 1px 4px 1px rgba(70, 70, 70, 0.329) !important;
}

.MuiDayCalendar-weekDayLabel {
  font-weight: var(--default-font-weight) !important;
  color: var(--app-bar-bg) !important;
  height: 40px !important;
}

/* ~ Weekend Label */
.MuiDayCalendar-weekDayLabel:nth-of-type(1),
.MuiDayCalendar-weekDayLabel:nth-of-type(7) {
  color: rgb(214, 40, 40) !important;
}

/* ! YEAR PICKER */
.MuiYearCalendar-root {
  display: flex;
  justify-self: center;
  min-width: 100% !important;
}

.MuiPickersYear-yearButton {
  font-size: 17px !important;
  font-weight: var(--default-font-weight) !important;
}

.MuiPickersYear-yearButton:hover {
  background-color: rgba(53, 162, 184, 0.771) !important;
  color: var(--btn-white-text) !important;
}

.MuiPickersYear-yearButton.Mui-selected {
  background-color: rgb(69, 168, 188) !important;
  color: var(--btn-white-text) !important;
  font-weight: var(--default-font-weight);
}

/* ! MONTH PICKER */
.MuiMonthCalendar-root {
  display: flex;
  justify-self: center;
  min-width: 100% !important;
}

.MuiPickersMonth-monthButton {
  font-size: 18px !important;
  font-weight: var(--default-font-weight) !important;
}

.MuiPickersMonth-monthButton.Mui-disabled {
  opacity: 0.5 !important;
}

.MuiPickersMonth-monthButton:not(.Mui-disabled):hover {
  background-color: rgba(53, 162, 184, 0.771) !important;
  color: var(--btn-white-text) !important;
}

.MuiPickersMonth-monthButton {
  color: rgb(45, 45, 45) !important;
}

.MuiPickersMonth-monthButton.Mui-selected {
  background-color: rgb(69, 168, 188) !important;
  color: var(--btn-white-text) !important;
  font-weight: var(--default-font-weight);
}

#date-form.required {
  outline: 2px solid var(--input-outline-required) !important;
  box-shadow: var(--input-box-shadow);
}

#date-form.revise-input {
  outline: 2px solid var(--input-outline-required) !important;
  border: none;
}

#date-form.revised-input {
  outline: 2px solid var(--input-outline-revised) !important;
}

.date-helper-text {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 52px;
  right: 0px;
  height: auto;
}

.date-helper-invalid-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
}

.date-helper-revised-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
}

.date-invalid-input-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(219, 29, 29) !important;
  outline: 2px solid rgb(219, 29, 29) !important;
  text-shadow: 0px 0.5px 0.5px rgba(139, 10, 10, 0.406) !important;
  margin: 0px 0px 0px 0px !important;
  padding: 4px 0px !important;
  text-align: center;
  text-indent: 0px;
  font-size: 13.5px;
  max-width: 400px !important;
  height: auto;
  letter-spacing: 0.25px;
  font-weight: 550 !important;
  background-color: var(--input-white-bg);
  line-height: 1.2;
  text-rendering: optimizeLegibility !important;
  z-index: 998 !important;
}

.date-revised-input-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(246, 188, 51) !important;
  outline: 2px solid rgb(246, 188, 51);
  background-color: #2e3335ec;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  text-indent: 0px;
  font-size: 13.5px;
  max-width: 400px !important;
  height: auto;
  max-height: 50px;
  letter-spacing: 0.25px;
  font-weight: 550 !important;
  line-height: 1.2;
  text-rendering: optimizeLegibility !important;
  z-index: 998 !important;
}

@media screen and (width < 1450px) {
  .input-date-container {
    width: 890px;
    align-self: center;
  }

  #date-form-label {
    font-size: 20px;
  }

  .date-info-container {
    width: 465px;
    height: 60px;
  }

  #date-form {
    width: 400px;
    height: 40px;
  }

  .date-field-root {
    width: 100%;
    height: 100%;
  }

  .date-field-root #date-field {
    font-size: 17px;
  }

  #date-field-label {
    transform: translate(11px, 9px) scale(0.95);
  }

  #date-field-label.MuiInputLabel-shrink {
    transform: translate(3px, -18px) scale(0.75) !important;
  }

  #date-field-adornment {
    height: 25px;
    width: 25px;
  }

  .date-info-circ {
    height: 28px;
    width: 28px;
  }

  /* -Calendar */
  #date-popper,
  #date-desktop-paper,
  .MuiDateCalendar-root,
  .date-content-wrapper {
    width: 400px !important;
  }

  #date-calendar-header {
    padding: 8px !important;
    margin: 0 !important;
  }

  #date-calendar-header .MuiPickersCalendarHeader-label {
    font-size: 20px;
    margin-left: 15px;
  }

  #date-switch-view-btn {
    padding: 3px;
  }

  #date-switch-view-icon,
  #date-left-arrow,
  #date-right-arrow {
    font-size: 26px;
  }

  .MuiDayCalendar-weekDayLabel {
    font-size: 18px !important;
    margin: 0 8.5px !important;
  }

  .MuiDayCalendar-weekContainer {
    min-width: 347px;
  }

  #calendar-day {
    font-size: 15px !important;
  }

  .MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    margin: 0 !important;
  }
}

@media screen and (1450px <= width < 1750px) {
  .input-date-container {
    width: 945px;
    align-self: center;
  }

  #date-form-label {
    font-size: 21px;
  }

  .date-info-container {
    width: 480px;
    height: 60px;
  }

  #date-form {
    width: 425px;
    height: 40px;
  }

  .date-field-root {
    width: 100%;
    height: 100%;
  }

  .date-field-root #date-field {
    font-size: 18px;
  }

  #date-field-label {
    transform: translate(11px, 9px) scale(1);
  }

  #date-field-label.MuiInputLabel-shrink {
    transform: translate(3px, -18px) scale(0.82) !important;
  }

  #date-field-adornment {
    height: 25px;
    width: 25px;
  }

  /* -Calendar */
  #date-popper,
  #date-desktop-paper,
  .MuiDateCalendar-root,
  .date-content-wrapper {
    width: 425px !important;
  }

  #date-calendar-header {
    padding: 8px !important;
    margin: 0 !important;
  }

  #date-calendar-header .MuiPickersCalendarHeader-label {
    font-size: 22px;
    margin-left: 15px;
  }

  #date-switch-view-btn {
    padding: 3px;
  }

  #date-switch-view-icon,
  #date-left-arrow,
  #date-right-arrow {
    font-size: 28px;
  }

  .MuiDayCalendar-weekDayLabel {
    font-size: 20px !important;
    margin: 0 8.5px !important;
  }

  .MuiDayCalendar-weekContainer {
    min-width: 347px;
  }

  #calendar-day {
    font-size: 16px !important;
  }

  .MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    margin: 0 !important;
  }
}

@media screen and (1450px <= width < 1650px) {
  .date-info-circ {
    height: 30px;
    width: 30px;
  }
}

@media screen and (1650px <= width < 1750px) {
  .date-info-circ {
    height: 30px;
    width: 30px;
  }
}

@media screen and (2150px <= width) {
  .input-date-container {
    width: 1150px;
    align-self: center;
  }

  #date-form-label {
    font-size: 24px;
  }

  .date-info-container {
    width: 550px;
    height: 70px;
    padding: 5px 0;
  }

  #date-form {
    width: 490px;
    height: 45px;
    margin: 0;
    padding: 0;
  }

  .date-field-root {
    width: 490px;
  }

  .date-field-root #date-field {
    font-size: 18px;
  }

  #date-field-label {
    transform: translate(11px, 10px) scale(1.2);
  }

  #date-field-label.MuiInputLabel-shrink {
    transform: translate(3px, -21px) scale(0.85) !important;
  }

  #date-field-adornment {
    height: 28px !important;
    width: 28px !important;
  }

  .date-info-circ {
    height: 31px;
    width: 31px;
  }

  /* -Calendar */
  #date-popper,
  #date-desktop-paper,
  .MuiDateCalendar-root,
  .date-content-wrapper {
    width: 470px !important;
  }

  #date-calendar-header {
    padding: 8px !important;
    margin: 0 !important;
  }

  #date-calendar-header .MuiPickersCalendarHeader-label {
    font-size: 22px;
    margin-left: 15px;
  }

  #date-switch-view-btn {
    padding: 3px;
  }

  #date-switch-view-icon {
    font-size: 28px;
  }

  #date-left-arrow,
  #date-right-arrow {
    font-size: 28px;
  }

  .MuiDayCalendar-weekDayLabel {
    font-size: 21px !important;
    margin: 0 10px !important;
  }

  .MuiDayCalendar-weekContainer {
    min-width: 367px;
  }

  #calendar-day {
    font-size: 16px !important;
  }

  .MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    margin: 0 !important;
  }
}

@media screen and (1750px <= width < 2150px) {
  .input-date-container {
    width: 1090px;
    align-self: center;
  }

  #date-form-label {
    font-size: 22px;
  }

  .date-info-container {
    width: 535px;
    height: 70px;
    padding: 5px 0;
  }

  #date-form {
    width: 470px;
    height: 44px;
    margin: 0;
    padding: 0;
  }

  .date-field-root {
    width: 470px;
  }

  .date-field-root #date-field {
    font-size: 18px;
  }

  #date-field-label {
    transform: translate(11px, 10px) scale(1.1);
  }

  #date-field-label.MuiInputLabel-shrink {
    transform: translate(3px, -21px) scale(0.85) !important;
  }

  #date-field-adornment {
    height: 25px;
    width: 25px;
  }

  .date-info-circ {
    height: 30px;
    width: 30px;
  }

  /* -Calendar */
  #date-popper,
  #date-desktop-paper,
  .MuiDateCalendar-root,
  .date-content-wrapper {
    width: 470px !important;
  }

  #date-calendar-header {
    padding: 8px !important;
    margin: 0 !important;
  }

  #date-calendar-header .MuiPickersCalendarHeader-label {
    font-size: 22px;
    margin-left: 15px;
  }

  #date-switch-view-btn {
    padding: 3px;
  }

  #date-switch-view-icon {
    font-size: 28px;
  }

  #date-left-arrow,
  #date-right-arrow {
    font-size: 28px;
  }

  .MuiDayCalendar-weekDayLabel {
    font-size: 21px !important;
    margin: 0 10px !important;
  }

  .MuiDayCalendar-weekContainer {
    min-width: 367px;
  }

  #calendar-day {
    font-size: 16px !important;
  }

  .MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    margin: 0 !important;
  }
}
