#uploadFilesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  margin: 0 0 5px 0;
  padding: 0;
  outline: 2px dashed var(--outline-color);
  border-radius: 8px;
  min-width: 95%;
  max-width: 95%;
}

#dragDropButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 5px 0;
}

#dragDropContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #255d72;
  text-align: center;
  cursor: pointer;
  margin: 0;
  padding: 2px 0;
  width: 100%;
}

#dragDropContainer:hover {
  background-color: #1f5366;
}

.uploadFileIcon#uploadFileIcon {
  font-size: 45px;
  margin: 0 !important;
  padding: 0 !important;
  color: var(--argus-white);
  opacity: 0.85;
  pointer-events: none;
}

#dragDropText {
  color: var(--argus-white);
  font-size: 14px;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  pointer-events: none;
}

#uploadedFiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: var(--argus-black);
  height: 100%;
  width: 100%;
  padding: 2px 4px;
  margin: 0;
}

#uploadedFilesHeader {
  font-size: 15px;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  color: var(--argus-white);
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

#uploadedFilesList {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
  height: inherit;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  gap: 12px;
}

.uploaded-file {
  list-style: none;
}

.file-status-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  z-index: 999;
}

.uploaded-file-name {
  color: var(--argus-white) !important;
  font-family: var(--default-font) !important;
  font-weight: 650 !important;
  font-size: 11px !important;
}

.uploaded-file-name:hover {
  color: rgb(246, 246, 246) !important;
  cursor: pointer;
  text-decoration: underline;
}

.delete-file-icon {
  height: 12px !important;
  width: 12px !important;
  fill: rgb(236, 17, 17) !important;
  stroke: rgb(236, 17, 17) !important;
  stroke-width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}

.delete-file-icon:hover {
  cursor: pointer;
  fill: rgb(182, 14, 14) !important;
  stroke: rgb(182, 14, 14) !important;
}

.clearFiles#clearButton {
  background-color: var(--btn-red);
  height: 25px;
  width: 40px;
  padding: 0;
  font-family: var(--default-font) !important;
  letter-spacing: 0;
  font-weight: var(--default-font-weight) !important;
  font-size: 13px !important;
  line-height: 1 !important;
}

#uploadProgressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: max-content;
  max-width: max-content;
  margin: 0;
  padding: 5px;
}

#uploadProgressText {
  color: var(--argus-white);
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  font-size: 14px;
}

#uploadFileProgress {
  color: white;
  fill: white;
  min-height: 35px;
  max-height: 35px;
  min-width: 35px;
  max-width: 35px;
  margin: 4px;
}

#progressPercent {
  color: white;
  /* font-family: var(--default-font); */
  font-weight: 800;
  letter-spacing: 0.1;
  font-size: 11px;
}

@media screen and (width < 1450px) {
  #uploadFilesContainer {
    width: 890px;
  }

  #dragDropText {
    font-size: 12px;
  }

  .uploadFileIcon#uploadFileIcon {
    font-size: 40px;
  }
}

@media screen and (1450px <= width < 1750px) {
  #uploadFilesContainer {
    width: 945px;
  }

  #dragDropText {
    font-size: 13px;
  }

  .uploadFileIcon#uploadFileIcon {
    font-size: 42px;
  }

  .uploaded-file-name {
    font-size: 10px !important;
  }

  .delete-file-icon {
    height: 11px !important;
    width: 11px !important;
  }
}

@media screen and (1750px <= width < 2150px) {
  #uploadFilesContainer {
    width: 1090px;
  }

  #dragDropText {
    font-size: 14px;
  }

  .uploadFileIcon#uploadFileIcon {
    font-size: 45px;
  }
}

@media screen and (2150px <= width) {
  #uploadFilesContainer {
    width: 1150px;
  }

  #dragDropText {
    font-size: 14px;
  }

  .uploadFileIcon#uploadFileIcon {
    font-size: 45px;
  }
  .uploaded-file-name {
    font-size: 12px !important;
  }
}
