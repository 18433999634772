.input-radio-group-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  padding: 10px;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  min-width: 95%;
  max-width: 95%;
}

.input-info-radio-group-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 260px;
  max-height: fit-content;
  min-height: fit-content;
  margin: 0;
  padding: 0;
  position: relative;
  right: 223px;
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}

.radio-group-form-label {
  font-size: 20px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.radio-grouping {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  width: 195px;
  padding: 2px 0;
  margin: 0;
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.radio-group-form-control-label#true-label-group,
.radio-group-form-control-label#false-label-group {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  min-width: max-content;
  margin: 0 0 0 0 !important;
  padding: 2px 0 !important;
  height: max-content;
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.radio-grouping.radio-group-row {
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
}

/* .radio-group-form-control-label {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-evenly;
  min-width: max-content;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  height: 40px;
} */

#true-label-group {
  width: fit-content;
}

#false-label-group {
  width: fit-content;
}

.radio-group-form-control-label .radio-group-btn-true,
.radio-group-form-control-label .radio-group-btn-false {
  pointer-events: auto !important;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  border: none !important;
  outline: none !important;
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.radio-group-btn-true .PrivateSwitchBase-input {
  border: none !important;
  outline: none !important;
}

.radio-group-btn-false .PrivateSwitchBase-input {
  border: none !important;
  outline: none !important;
}

.radio-group-btn-true.PrivateSwitchBase-root.Mui-disabled:hover,
.radio-group-btn-false.PrivateSwitchBase-root.Mui-disabled:hover {
  cursor: not-allowed;
}

.radio-group-btn-label {
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: 0px;
  pointer-events: none !important;
  user-select: none !important;
  font-weight: var(--default-font-weight) !important;
  width: fit-content;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.radio-group-btn-icon-true {
  background-color: var(--input-white-bg);
  outline: 1px solid var(--input-white-bg) !important;
  border: none !important;
  border-color: transparent !important;
  border-radius: 3px;
  fill: var(--input-white-bg);
  box-shadow: var(--radio-box-shadow) !important;
  margin: 0;
  padding: 0;
  z-index: 1 !important;
}

.radio-group-btn-icon-false {
  background-color: var(--input-white-bg);
  outline: 1px solid var(--input-white-bg) !important;
  border: none !important;
  border-radius: 3px;
  fill: var(--input-white-bg) !important;
  color: var(--input-white-bg) !important;
  box-shadow: var(--radio-box-shadow) !important;
  margin: 0;
  padding: 0;
  z-index: 1 !important;
}

.radio-group-btn-false .radio-group-btn-icon-false {
  outline: 1px solid var(--input-white-bg) !important;
  color: var(--input-white-bg) !important;
}

.radio-group-btn-false.radio-group-btn-checked .radio-group-btn-icon-false {
  background-color: var(--radio-btn-red) !important;
  outline: 1px solid var(--radio-btn-red) !important;
  border: none !important;
  border-radius: 3px !important;
  margin: 0;
  padding: 0;
}

.radio-group-btn-true .radio-group-btn-icon-true {
  outline: 1px solid var(--input-white-bg) !important;
  color: var(--input-white-bg);
}

.radio-group-btn-true.radio-group-btn-checked .radio-group-btn-icon-true {
  fill: var(--btn-green) !important;
  color: var(--btn-green) !important;
  outline: 1px solid var(--btn-green) !important;
  border: none;
  border-radius: 3px;
  margin: 0;
  padding: 0;
}

.radio-group-btn-true input {
  border: none !important;
  outline: none !important;
}

.radio-group-btn-false.radio-group-btn-checked .radio-group-btn-icon-false {
  fill: var(--input-white-bg) !important;
  border-radius: 3px;
  margin: 0;
  padding: 0;
}

/* General Info Slide */
#onNativeLand-label {
  line-height: 1.4;
  text-align: left;
  text-wrap: normal;
  max-width: 350px;
}

/* Project Info Slide */
#prevAgricultureLand-label {
  width: 300px;
  line-height: 1.4;
  text-align: left;
  position: relative;
  top: 3px;
}

#ownerGCCheck-label {
  max-width: 350px;
  width: fit-content;
  text-align: left;
}

/* SMALL */
@media screen and (width < 1450px) {
  .radio-group-form-label {
    font-size: 20px;
  }

  .input-radio-group-container {
    width: 890px;
  }

  .input-info-radio-group-container {
    width: 250px;
    right: 215px;
  }
  .radio-group-info-circ {
    height: 28px;
    width: 28px;
  }
  .radio-group-btn-label {
    font-size: 21px !important;
  }
  .radio-group-btn-true .radio-group-btn-icon-true,
  .radio-group-btn-false .radio-group-btn-icon-false {
    height: 27px;
    width: 27px;
  }
  .radio-group-btn-true.radio-group-btn-checked .radio-group-btn-icon-true,
  .radio-group-btn-false.radio-group-btn-checked .radio-group-btn-icon-false {
    height: 27px;
    width: 27px;
  }

  .radio-grouping {
    width: 190px;
  }

  .radio-group-form-control-label#true-label-group {
    width: 85px;
  }

  .radio-group-form-control-label#false-label-group {
    width: 75px;
  }

  .radio-group-btn-label {
    font-size: 20px !important;
  }

  #onNativeLand-label {
    width: 300px;
  }

  #ownerGCCheck-label {
    max-width: 300px;
  }
}

@media screen and (1450px <= width < 1750px) {
  .radio-group-form-label {
    font-size: 21px !important;
  }

  .input-radio-group-container {
    width: 945px;
  }

  .input-info-radio-group-container {
    width: 250px;
    right: 230px;
  }

  .radio-group-btn-true .radio-group-btn-icon-true,
  .radio-group-btn-false .radio-group-btn-icon-false {
    height: 30px;
    width: 30px;
  }
  .radio-group-btn-true.radio-group-btn-checked .radio-group-btn-icon-true,
  .radio-group-btn-false.radio-group-btn-checked .radio-group-btn-icon-false {
    height: 30px;
    width: 30px;
  }

  .radio-grouping {
    width: 205px;
  }

  .radio-group-form-control-label#true-label-group {
    width: 90px;
  }

  .radio-group-form-control-label#false-label-group {
    width: 80px;
  }

  .radio-group-btn-label {
    font-size: 20px !important;
  }

  #onNativeLand-label {
    width: 315px;
  }

  #ownerGCCheck-label {
    max-width: 350px;
  }
}

@media screen and (1750px <= width < 2150px) {
  .radio-group-form-label {
    font-size: 22px !important;
  }
  .input-radio-group-container {
    width: 1090px;
  }

  .input-info-radio-group-container {
    width: 275px;
    right: 260px;
  }

  .radio-grouping {
    width: 215px;
  }

  .radio-group-form-control-label#true-label-group {
    width: 90px;
  }

  .radio-group-form-control-label#false-label-group {
    width: 80px;
  }

  .radio-group-btn-true .radio-group-btn-icon-true,
  .radio-group-btn-false .radio-group-btn-icon-false {
    height: 32px;
    width: 32px;
  }
  .radio-group-btn-true.radio-group-btn-checked .radio-group-btn-icon-true,
  .radio-group-btn-false.radio-group-btn-checked .radio-group-btn-icon-false {
    height: 32px;
    width: 32px;
  }

  #onNativeLand-label {
    max-width: 450px;
    width: fit-content;
  }

  #ownerGCCheck-label {
    max-width: 450px;
  }

  .radio-group-info-circ {
    height: 30px;
    width: 30px;
  }

  .radio-group-btn-label {
    font-size: 21px !important;
  }
}

@media screen and (2150px <= width) {
  .radio-group-form-label {
    font-size: 24px !important;
  }
  .input-radio-group-container {
    width: 1150px;
  }

  .input-info-radio-group-container {
    width: 285px;
    right: 260px;
  }

  .radio-grouping {
    width: 225px;
  }

  .radio-group-form-control-label#true-label-group {
    width: 95px;
  }

  .radio-group-form-control-label#false-label-group {
    width: 85px;
  }

  .radio-group-btn-true .radio-group-btn-icon-true,
  .radio-group-btn-false .radio-group-btn-icon-false {
    height: 33px;
    width: 33px;
  }
  .radio-group-btn-true.radio-group-btn-checked .radio-group-btn-icon-true,
  .radio-group-btn-false.radio-group-btn-checked .radio-group-btn-icon-false {
    height: 33px;
    width: 33px;
  }

  #onNativeLand-label {
    width: 515px;
  }

  .radio-group-info-circ {
    height: 31px;
    width: 31px;
  }

  .radio-group-btn-label {
    font-size: 22px !important;
  }
}
