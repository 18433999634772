#map {
  padding: 0px;
  margin: 0px;
}

.new-map {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
}

#start:hover,
#end:hover {
  cursor: pointer !important;
}

#pin-text {
  color: red;
  font-size: 18px;
  font-weight: bold;
  font-family: var(--default-font);
  z-index: 9990;
}

.map-container {
  display: flex;
  align-self: center;
  height: 550px;
  min-width: 95%;
  width: 95%;
  max-width: 95%;
  border: 3px solid var(--outline-color);
  padding: 0px;
  margin: 0px 0px 15px 0px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(3, 8, 15, 0.09) 0px 1px 1px,
    rgba(0, 0, 0, 0.217) 0px 4px 5px, rgba(2, 1, 15, 0.09) 0px 2px 3px,
    rgba(0, 0, 0, 0.09) 0px 6px 6px;
  border-radius: 5px;
}

.autocomplete-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 0 15px;
  padding: 0;
  border-radius: 5px;
  width: 100%;
  min-width: 440px;
  max-width: 440px;
}

.autocomplete-container {
  width: 100%;
}

.autocomplete-container .autocomplete-textfield {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--input-text-color);
  background-color: var(--input-white-bg);
  border: none;
  outline: none;
  border-radius: 5px;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  box-shadow: var(--input-box-shadow);
  outline: 2px solid var(--argus-blue-dark);
  height: 40px;
  box-sizing: border-box;
}

.autocomplete-container .autocomplete-textfield:focus,
.autocomplete-container .autocomplete-textfield:focus-within {
  outline: none !important;
  border: nonw !important;
}

.autocomplete-textfield.autocomplete-textfield-root
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.autocomplete-container #autocomplete-input {
  text-indent: 10px;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 13px;
  height: 40px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  border-radius: 5px;
}

.autocomplete-container #autocomplete-input::placeholder {
  background-color: transparent !important;
  color: transparent !important;
}

#search-icon {
  fill: var(--argus-blue-dark);
  height: 22px;
  width: 22px;
}

.pac-container {
  width: 440px !important;
  background-color: var(--input-white-bg);
}

.autocomplete-input-label#autocomplete-input-label {
  font-family: var(--default-font);
  color: var(--input-label-color) !important;
  font-weight: 500;
  font-size: 14px;
  transform: translate(14px, 9px) scale(1);
}

.autocomplete-input-label#autocomplete-input-label.autocomplete-input-label-shrink {
  display: none;
}
