.input-select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 95% !important;
  min-width: 95% !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
}

.select-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  font-family: var(--default-font);
  margin: 0;
  padding: 5px 0;
  position: relative;
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}

.select-form-label {
  font-size: 20px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
}

/* ! FORM CONTROL */
#select-form {
  box-shadow: var(--input-box-shadow);
  background-color: var(--input-white-bg);
  border-radius: 5px;
  border: none;
  outline: none;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#select-form.required {
  outline: 2px solid var(--input-outline-required) !important;
  box-shadow: var(--input-box-shadow);
}

#select-form.revise-input {
  outline: 2px solid var(--input-outline-required) !important;
  border: none;
}

#select-form.revised-input {
  outline: 2px solid var(--input-outline-revised) !important;
}

.select-field-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  background-color: transparent;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

.select-field-root .MuiOutlinedInput-notchedOutline,
.select-field-root .MuiOutlinedInput-notchedOutline:focus-within {
  display: none;
  border: none !important;
  outline: none !important;
}

.select-field-menu#select-field-menu {
  border-radius: 5px !important;
}

/** TEXT FIELD */
#select-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-indent: 8px;
  color: var(--input-text-color) !important;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  padding: 0;
  margin: 0;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#select-field.disable-text {
  border: 2px solid rgba(102, 102, 102, 0.724);
}

#select-field:disabled:hover {
  cursor: not-allowed;
}

#select-input {
  box-shadow: none;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  height: inherit;
  display: flex;
  align-items: center;
}

.select-field-menu#select-field-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.select-field-paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 !important;
  margin: 0 !important;
  overflow-y: scroll;
}

.select-field-paper .MuiList-root {
  padding: 0 !important;
  margin: 0 !important;
}

.select-placeholder#select-field-placeholder {
  font-family: var(--default-font) !important;
  color: var(--btn-red) !important;
  font-weight: 600 !important;
}

.select-placeholder#select-field-placeholder:hover {
  cursor: pointer;
  background-color: var(--btn-red-hover) !important;
  color: var(--btn-white-text) !important;
}

.select-menu-item {
  font-family: var(--default-font) !important;
  color: var(--input-text-color) !important;
  font-weight: var(--default-font-weight) !important;
}

.select-menu-item:hover {
  background-color: var(--argus-blue-dark) !important;
  color: var(--btn-white-text) !important;
}

.select-menu-item.Mui-selected {
  background-color: var(--main-blue) !important;
  color: var(--btn-white-text) !important;
}

.select-menu-item:hover.Mui-selected {
  background-color: var(--main-blue) !important;
  color: var(--btn-white-text) !important;
}

#select-field-label {
  font-family: var(--default-font);
  color: var(--input-label-color) !important;
  font-weight: var(--default-font-weight) !important;
  transform: translate(14px, 12px) scale(1);
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) !important;
}

#select-field-label.select-field-label-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(3px, -21px) scale(0.75) !important;
}

@media screen and (width < 1450px) {
  .select-form-label {
    font-size: 20px !important;
  }

  .input-select-container {
    width: 890px;
    align-self: center;
    margin-bottom: 15px;
    padding: 10px 15px;
  }

  .select-info-container {
    width: 465px;
  }

  #select-form {
    width: 400px;
    height: 40px;
  }

  .select-field-root {
    width: 100% !important;
  }

  #select-field {
    font-size: 17px !important;
    width: 100%;
  }

  .select-field-menu#select-field-menu {
    max-height: 325px;
    min-height: 325px;
  }

  .select-field-paper {
    max-height: 325px;
    min-height: 325px;
    overflow-y: scroll;
  }

  #select-field-label,
  .revise-input #select-field-label,
  .revised-input #select-field-label {
    transform: translate(11px, 9px) scale(0.95) !important;
  }

  #select-field-label.select-field-label-shrink {
    transform: translate(3px, -18px) scale(0.75) !important;
  }
}

@media screen and (1450px <= width < 1750px) {
  .select-form-label {
    font-size: 21px !important;
  }

  .input-select-container {
    width: 945px;
    align-self: center;
    margin-bottom: 15px;
    padding: 10px 15px;
  }

  .select-info-container {
    width: 480px;
    height: 60px;
  }

  #select-form {
    width: 425px;
    height: 40px;
  }

  .select-field-root {
    width: 100% !important;
    height: 40px !important;
  }

  #select-field {
    font-size: 18px !important;
    width: 100%;
    height: 40px;
  }

  .select-field-menu#select-field-menu {
    max-height: 350px;
    min-height: 350px;
  }

  .select-field-paper {
    max-height: 350px;
    min-height: 350px;
    overflow-y: scroll;
  }

  #select-field-label,
  .revise-input #select-field-label,
  .revised-input #select-field-label {
    transform: translate(11px, 9px) scale(1) !important;
  }

  #select-field-label.select-field-label-shrink {
    transform: translate(3px, -20px) scale(0.82) !important;
  }
}

@media screen and (1750px <= width < 2150px) {
  .input-select-container {
    width: 1090px;
    align-self: center;
    margin-bottom: 15px;
    padding: 10px 15px;
  }

  .select-info-container {
    width: 535px;
    height: 70px;
    padding: 5px 0;
  }

  .select-form-label {
    font-size: 22px !important;
  }

  #select-form {
    width: 470px;
    height: 44px;
    margin: 0;
    padding: 0;
  }

  .select-field-root {
    width: 100% !important;
    height: 44px !important;
  }

  #select-field {
    font-size: 18px !important;
    width: 100%;
    height: 44px;
  }

  #select-input {
    height: inherit;
    width: 100%;
  }

  #select-field-label,
  .revise-input #select-field-label,
  .revised-input #select-field-label {
    transform: translate(11px, 10px) scale(1.1);
  }

  .select-field-menu#select-field-menu {
    max-height: 350px;
    min-height: 350px;
  }

  .select-field-paper {
    max-height: 350px;
    min-height: 350px;
    overflow-y: scroll;
  }

  #select-field-label.select-field-label-shrink {
    transform: translate(3px, -21px) scale(0.85) !important;
  }
}

@media screen and (2150px <= width) {
  .input-select-container {
    width: 1150px;
    align-self: center;
    margin-bottom: 15px;
    padding: 10px 15px;
  }

  .select-info-container {
    width: 550px;
    height: 70px;
    padding: 5px 0;
  }

  .select-form-label {
    font-size: 24px !important;
  }

  #select-form {
    width: 490px;
    height: 45px;
    margin: 0 !important;
    padding: 0 !important;
  }

  .select-field-root {
    width: 100% !important;
    height: 45px !important;
  }

  #select-field {
    font-size: 18px !important;
    width: 100%;
    height: 45px;
  }

  .select-field-menu#select-field-menu {
    max-height: 360px;
    min-height: 360px;
  }

  .select-field-paper {
    max-height: 360px;
    min-height: 360px;
    overflow-y: scroll;
  }

  #select-field-label,
  .revise-input #select-field-label,
  .revised-input #select-field-label {
    transform: translate(11px, 10px) scale(1.2);
  }

  #select-field-label.select-field-label-shrink {
    transform: translate(3px, -21px) scale(0.85) !important;
  }
}
