.cb-sign-container {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  height: 40px;
}

#sign3x3-sign-container {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  gap: 25px;
}

#signStand-sign-container {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  gap: 25px;
}

.radio-form-control-label-sign {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
  padding: 5px 0;
}

.radio-form-control-label-sign .radio-btn-true {
  pointer-events: auto !important;
  margin: 0;
  padding: 0;
  border: 0 !important;
  outline: 0 !important;
}

.radio-form-control-label .radio-btn-true,
.radio-form-control-label .radio-btn-false {
  pointer-events: auto !important;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  border: none !important;
  outline: none !important;
}

.radio-btn-true .PrivateSwitchBase-input {
  border: none !important;
  outline: none !important;
}

.radio-btn-false .PrivateSwitchBase-input {
  border: none !important;
  outline: none !important;
}

.radio-btn-true.PrivateSwitchBase-root.Mui-disabled:hover,
.radio-btn-false.PrivateSwitchBase-root.Mui-disabled:hover {
  cursor: not-allowed;
}

.radio-btn-label {
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: 0px;
  pointer-events: none !important;
  user-select: none !important;
  width: fit-content;
}

/* > */
.radio-btn-icon-true {
  background-color: var(--input-white-bg);
  outline: 1px solid var(--input-white-bg) !important;
  border: 0 !important;
  border-color: transparent !important;
  border-radius: 3px;
  fill: var(--input-white-bg);
  box-shadow: var(--radio-box-shadow);
  margin: 0;
  padding: 0;
  z-index: 1 !important;
}

/* > */
.radio-btn-icon-false {
  background-color: var(--input-white-bg);
  outline: 1px solid var(--input-white-bg) !important;
  border: 0 !important;
  border-radius: 3px;
  fill: var(--input-white-bg);
  color: var(--input-white-bg);
  box-shadow: var(--radio-box-shadow);
  margin: 0;
  padding: 0;
  z-index: 1 !important;
}

/* > */
.radio-btn-false .radio-btn-icon-false {
  border: 1px solid var(--input-white-bg);
  outline: 1px solid var(--input-white-bg) !important;
  color: var(--input-white-bg);
}

.radio-btn-false.radio-btn-checked .radio-btn-icon-false {
  background-color: var(--radio-btn-red);
  outline: 1px solid var(--radio-btn-red) !important;
  border: none;
  border-radius: 3px;
  margin: 0;
  padding: 0;
}

.radio-btn-true .radio-btn-icon-true {
  border: 1px solid var(--input-white-bg);
  outline: 1px solid var(--input-white-bg) !important;
  color: var(--input-white-bg);
}

.radio-btn-true.radio-btn-checked .radio-btn-icon-true {
  fill: var(--btn-green) !important;
  color: var(--btn-green) !important;
  outline: 1px solid var(--btn-green) !important;
  border: none;
  border-radius: 3px;
  margin: 0;
  padding: 0;
}

.radio-btn-true input {
  border: none !important;
  outline: none !important;
}

.radio-btn-false.radio-btn-checked .radio-btn-icon-false {
  fill: var(--input-white-bg);
  border-radius: 3px;
  margin: 0;
  padding: 0;
}

#sign3x3-label-group {
  width: 175px;
}

#signStand-label-group {
  width: 198px;
}

/** Types */
#t1-sign-container,
#t2-sign-container,
#t3-sign-container,
#t4-sign-container,
#t5-sign-container,
#t6-sign-container,
#t7-sign-container {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 360px;
  padding: 0 !important;
  margin: 0 !important;
  height: 35px;
}

#t8-sign-container,
#t9-sign-container,
#t10-sign-container,
#t11-sign-container,
#t12-sign-container,
#t13-sign-container,
#other-sign-container {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 360px;
  padding: 0 !important;
  margin: 0 !important;
  height: 35px;
}

#t1-label-group,
#t2-label-group,
#t3-label-group,
#t4-label-group,
#t5-label-group,
#t6-label-group,
#t7-label-group {
  width: 295px;
  margin: 0px;
  padding: 0px;
}

#t8-label-group,
#t9-label-group,
#t10-label-group,
#t11-label-group,
#t12-label-group,
#t13-label-group,
#other-label-group {
  width: 295px;
  margin: 0px;
  padding: 0px;
}

.radio-btn-label-sign {
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: 0px;
  pointer-events: none !important;
  user-select: none !important;
  font-weight: var(--default-font-weight) !important;
}

.radio-btn-true {
  border: none !important;
  outline: none !important;
}

.radio-btn-true.required {
  border-radius: 3px;
  outline: 3px solid red;
}

#info-t1,
#info-t2,
#info-t3,
#info-t4,
#info-t5,
#info-t6,
#info-t7,
#info-t8,
#info-t9,
#info-t10,
#info-t11,
#info-t12,
#info-t13,
#info-other {
  height: 32px;
  width: 32px;
  margin: 0;
  padding: 0;
}

/* SMALL */
@media screen and (max-width: 1449px) {
  .radio-btn-label-sign {
    font-size: 21px !important;
  }
  .radio-btn-true .radio-btn-icon-true,
  .radio-btn-false .radio-btn-icon-false {
    height: 25px;
    width: 25px;
  }
  .radio-btn-true.radio-btn-checked .radio-btn-icon-true,
  .radio-btn-false.radio-btn-checked .radio-btn-icon-false {
    height: 25px;
    width: 25px;
  }
}

/* MEDIUM */
@media screen and (width < 1450px) {
  .radio-info-circ {
    height: 28px;
    width: 28px;
  }
  .radio-btn-label-sign {
    font-size: 21px !important;
  }
  .radio-btn-true .radio-btn-icon-true,
  .radio-btn-false .radio-btn-icon-false {
    height: 28px;
    width: 28px;
  }
  .radio-btn-true.radio-btn-checked .radio-btn-icon-true,
  .radio-btn-false.radio-btn-checked .radio-btn-icon-false {
    height: 28px;
    width: 28px;
  }
}

@media screen and (1450px <= width < 1750px) {
  .radio-info-circ {
    height: 29px;
    width: 29px;
  }

  .radio-form-control-label-sign#sign3x3-label-group {
    width: 165px !important;
  }

  .radio-form-control-label-sign#signStand-label-group {
    width: 190px !important;
  }

  .radio-btn-true .radio-btn-icon-true,
  .radio-btn-false .radio-btn-icon-false {
    height: 28px;
    width: 28px;
  }
  .radio-btn-true.radio-btn-checked .radio-btn-icon-true,
  .radio-btn-false.radio-btn-checked .radio-btn-icon-false {
    height: 28px;
    width: 28px;
  }

  .radio-btn-label-sign {
    font-size: 21px !important;
  }
}

/* LARGE */
@media screen and (1750px <= width < 2150px) {
  .radio-info-circ {
    height: 30px;
    width: 30px;
  }

  .radio-btn-label-sign {
    font-size: 23px !important;
    margin-right: 15px !important;
    width: fit-content !important;
  }

  .radio-btn-true .radio-btn-icon-true,
  .radio-btn-false .radio-btn-icon-false {
    height: 32px;
    width: 32px;
  }
  .radio-btn-true.radio-btn-checked .radio-btn-icon-true,
  .radio-btn-false.radio-btn-checked .radio-btn-icon-false {
    height: 32px;
    width: 32px;
  }
}

@media screen and (2150px <= width) {
  .radio-form-control-label-sign#sign3x3-label-group {
    width: 195px !important;
  }

  .radio-form-control-label-sign#signStand-label-group {
    width: 220px !important;
  }

  .radio-info-circ {
    height: 31px;
    width: 31px;
  }

  #sign3x3-sign-container {
    width: 270px;
  }

  #signStand-sign-container {
    width: 305px;
  }

  .radio-btn-label-sign {
    font-size: 24px !important;
    margin-right: 10px !important;
    width: fit-content !important;
  }

  .radio-btn-true .radio-btn-icon-true,
  .radio-btn-false .radio-btn-icon-false {
    height: 32px;
    width: 32px;
  }
  .radio-btn-true.radio-btn-checked .radio-btn-icon-true,
  .radio-btn-false.radio-btn-checked .radio-btn-icon-false {
    height: 32px;
    width: 32px;
  }
}
