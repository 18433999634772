.saved-indicator-container {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  justify-self: center !important;
  align-self: center !important;
  margin: 0 !important;
  padding: 0;
  height: fit-content !important;
  width: fit-content !important;
  pointer-events: none;
}

#saved-indicator-icon.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  transition: all 120ms cubic-bezier(0.25, 1, 0.5, 1),
    opacity 25ms cubic-bezier(0.25, 1, 0.5, 1);
  /* display: none; */
}

#saving-progress-icon.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  transition: all 120ms cubic-bezier(0.25, 1, 0.5, 1),
    opacity 50ms cubic-bezier(0.25, 1, 0.5, 1);
  /* display: none; */
}

#saved-error-indicator-icon.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  /* display: none; */
}

#save-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  width: 60px;
  height: 60px;
}

#saved-indicator-icon:hover,
#saving-progress-icon:hover,
#saved-error-indicator-icon:hover {
  cursor: pointer;
}

#saving-progress-icon.visible {
  height: 60px !important;
  width: 60px !important;
  margin: 0;
  stroke: rgb(232, 232, 232);
  color: rgb(232, 232, 232);
  padding: 0;
  pointer-events: all;
  opacity: 1 !important;
  transition: all 120ms cubic-bezier(0.25, 1, 0.5, 1),
    opacity 20ms cubic-bezier(0.25, 1, 0.5, 1);
}

#saved-error-indicator-icon.visible {
  width: 40px !important;
  height: 40px !important;
  fill: rgb(232, 232, 232);
  background-color: rgb(208, 24, 24);
  stroke: rgb(232, 232, 232);
  stroke-width: 1px;
  padding: 5px !important;
  border-radius: 50%;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.199);
  pointer-events: all;
  opacity: 1;
}

#saved-indicator-icon.visible {
  width: 40px !important;
  height: 40px !important;
  fill: var(--main-blue);
  stroke: var(--main-blue);
  background-color: rgb(232, 232, 232);
  stroke-width: 1.5px;
  padding: 5px !important;
  margin: 0 !important;
  border-radius: 50%;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.199);
  pointer-events: all;
  opacity: 1;
  transition: all 0.1s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.1s cubic-bezier(0.25, 1, 0.5, 1);
}

#saving-progress-icon.visible {
  display: block;
  opacity: 1;
}

#saved-error-indicator-icon.visible {
  display: block;
  opacity: 1;
}

@media screen and (width < 1450px) {
  #saved-indicator-icon.visible,
  #saved-error-indicator-icon.visible {
    width: 30px !important;
    height: 30px !important;
    padding: 3px !important;
  }
  #saving-progress-icon.visible {
    width: 32px !important;
    height: 32px !important;
  }

  #save-icons-container {
    width: 40px;
    height: 40px;
  }
}

@media screen and (1450px <= width < 1750px) {
  #saved-indicator-icon.visible,
  #saved-error-indicator-icon.visible {
    width: 32px !important;
    height: 32px !important;
    padding: 3px !important;
  }
  #saving-progress-icon.visible {
    width: 34px !important;
    height: 34px !important;
  }

  #save-icons-container {
    width: 42px;
    height: 42px;
  }
}

@media screen and (1750px <= width < 2150px) {
  #saved-indicator-icon.visible,
  #saved-error-indicator-icon.visible {
    width: 32px !important;
    height: 32px !important;
    padding: 5px !important;
  }
  #saving-progress-icon.visible {
    width: 35px !important;
    height: 35px !important;
  }

  #save-icons-container {
    width: 42px;
    height: 42px;
  }
}
