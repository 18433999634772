/* + Main Container + */
.attach-file-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
}

#input-attach-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-self: center;
  padding: 5px 10px;
  transition: all 150ms ease 0s;
  min-width: 95%;
  max-width: 95%;
}

.attach-form-file-label {
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
  text-align: left;
}

/* + Radio Group + */
#attach-file-radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0 !important;
  margin: 0;
  transition: all 650ms ease 0s;
}

#attach-form-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  justify-content: flex-start;
  margin: 0 !important;
  padding: 5px 0 !important;
}

/* + Checkbox Label + */
#attach-form-control .attach-btn-file-label {
  text-shadow: var(--form-label-text-color) !important;
  color: var(--label-color) !important;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font) !important;
  pointer-events: none !important;
  user-select: none !important;
}

.attach-form-control-root .attached-radio-root,
.attach-form-control-root .not-available-radio-root,
.attach-form-control-root .not-applicable-radio-root {
  pointer-events: auto !important;
  padding: 0 !important;
}

#attach-form-control .attach-checkbox-icon {
  color: rgb(244, 244, 244);
  outline: 1px solid rgb(244, 244, 244);
  background-color: rgb(244, 244, 244);
  border: 0;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 2px 4px, rgba(0, 0, 0, 0.09) 0px 4px 8px,
    rgba(0, 0, 0, 0.09) 0px 6px 5px;
  margin: 0;
  padding: 0;
  z-index: 1 !important;
}

.attached-radio-root.attached-radio-checked .attach-checkbox-icon,
.not-available-radio-checked .attach-checkbox-icon,
.not-applicable-radio-checked .attach-checkbox-icon {
  fill: var(--btn-green) !important;
  color: var(--btn-green) !important;
  outline: 1px solid var(--btn-green) !important;
}

.attached-radio-root .PrivateSwitchBase-input,
.not-available-radio-root .PrivateSwitchBase-input,
.not-applicable-radio-root .PrivateSwitchBase-input {
  border: none !important;
  outline: none !important;
}

.attached-radio-root.PrivateSwitchBase-root.Mui-disabled:hover,
.not-available-radio-root.PrivateSwitchBase-root.Mui-disabled:hover,
.not-applicable-radio-root.PrivateSwitchBase-root.Mui-disabled:hover {
  cursor: not-allowed !important;
}

@media screen and (width < 1450px) {
  #input-attach-container {
    width: 890px;
  }

  #swmpPlan-attach-label {
    width: 345px;
  }
  #corpOfEngineersReport-attach-label {
    width: 345px;
  }
  #endangeredSpeciesReport-attach-label {
    width: 345px;
  }

  .attach-form-file-label {
    font-size: 18px;
  }

  #attach-file-radio-group {
    width: 435px;
  }

  #attach-form-control {
    gap: 12px;
  }

  #attach-form-control .attach-checkbox-icon {
    height: 19px;
    width: 19px;
  }

  #attach-form-control .attach-btn-file-label {
    font-size: 15px;
  }

  .text-info-circ.file-info-circ {
    height: 24px;
    width: 24px;
  }
}

@media screen and (1450px <= width < 1750px) {
  #input-attach-container {
    width: 945px;
  }

  .attach-form-file-label {
    font-size: 18px;
  }

  #attach-file-radio-group {
    width: 435px;
  }

  #attach-form-control {
    gap: 12px;
  }

  #attach-form-control .attach-checkbox-icon {
    height: 19px;
    width: 19px;
  }

  #attach-form-control .attach-btn-file-label {
    font-size: 15px;
  }

  .text-info-circ.file-info-circ {
    height: 24px;
    width: 24px;
  }
}

@media screen and (1750px <= width < 2150px) {
  #input-attach-container {
    width: 1090px;
  }

  .attach-form-file-label {
    font-size: 19px;
  }

  #attach-file-radio-group {
    width: 450px;
  }

  #attach-form-control {
    gap: 15px;
  }

  #attach-form-control .attach-checkbox-icon {
    height: 20px;
    width: 20px;
  }

  #attach-form-control .attach-btn-file-label {
    font-size: 16px;
  }

  .text-info-circ.file-info-circ {
    height: 25px;
    width: 25px;
  }
}

@media screen and (2150px <= width) {
  #input-attach-container {
    width: 1150px;
  }

  .attach-form-file-label {
    font-size: 19px;
  }

  #attach-file-radio-group {
    width: 475px;
  }

  #attach-form-control {
    gap: 15px;
  }

  #attach-form-control .attach-checkbox-icon {
    height: 20px;
    width: 20px;
  }

  #attach-form-control .attach-btn-file-label {
    font-size: 17px;
  }

  .text-info-circ.file-info-circ {
    height: 25px;
    width: 25px;
  }
}
